<template>
  <b-card class="shadow mx-auto" style="max-width: 802px">
    <b-card-body class="text-center" v-if="accountType === 1">
      <h2>{{$t('Validations.email.emailValidation')}}</h2>

      <p>
        {{$t('register.label.emailValidation',{email:$store.getters.user.Contact.Email})}}
      </p>

      <label for="">{{$t('register.label.enterCode')}}</label>

      <CodeInput
        :key="componentKey"
        class="mx-auto"
        :loading="false"
        :fields="4"
        :autoFocus="true"
        v-on:complete="buyerEmailComplete"
        v-on:change="onChange"
      />
      <div class="pb-4">
        <b-button variant="link" @click="resendEmail(true)">Resend the code</b-button>
        <b-toast
          id="emailVal"
          auto-hide-delay="3000"
          title="Email Validation"
          variant="success"
        >
          {{$t('register.label.emailSent')}}
        </b-toast>
        <section v-show="errorMessage">
          <b-alert variant="danger" show>{{ errorMessage }}</b-alert>
        </section>

        <b-alert variant="success" :show="sendMessage">
          {{$t('register.label.validationSent')}}
        </b-alert>
      </div>
    </b-card-body>
    <b-card-body v-else>
      <vue-good-wizard
        ref="sellerRegisterWizard"
        :steps="steps"
        :onNext="nextClicked"
        :onBack="backClicked"
        :nextStepLabel="$t('listing.label.next')"
        :previousStepLabel="$t('listing.label.back')"
      >
        <div slot="page1">
          <b-row>
            <b-col cols="12" class="text-center p-5">
              <h4>{{$t('register.label.WhichTypeOfSeller')}}</h4>
            </b-col>
            <b-col>
              <b-button
                class="w-100 text-left m-2 p-3 btn-tabular"
                :variant="sellerType == 1 ? 'primary' : 'secondary'"
                :class="sellerType != 1 ? 'bg-white' : ''"
                @click="selectSellerType(1)"
              >
                <h5 :class="sellerType != 1 ? 'text-primary' : ''">
                  {{$t('register.label.ProfessionalSeller')}}
                </h5>
                <p>{{$t('register.label.ProfessionalSellerDesc')}}</p>
              </b-button>
            </b-col>
            <b-col>
              <b-button
                class="w-100 text-left m-2 p-3 btn-tabular"
                :variant="sellerType == 0 ? 'primary' : 'secondary'"
                :class="sellerType != 0 ? 'bg-white' : ''"
                @click="selectSellerType(0)"
              >
                <h5 :class="sellerType != 0 ? 'text-primary' : ''">{{$t('register.label.PrivateSeller')}}</h5>
                <p>{{$t('register.label.PrivateSellerDesc')}}</p>
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div slot="page2" class="text-center">
          <h2>{{$t('Validations.email.emailValidation')}}</h2>

          <p>
            {{$t('register.label.emailValidation',{email:$store.getters.user.Contact.Email})}}
          </p>
          <div v-if="sellerEmail">
            <h3 class="text-success my-5">
              <b-icon-check-circle variant="success" class="mx-2"/>
              {{$t('register.label.EmailHasBeenVerified')}}
            </h3>
          </div>
          <div v-else>
            <label for="">{{$t('register.label.enterCode')}}</label>
            <CodeInput
              :key="componentKey"
              class="mx-auto"
              :loading="false"
              :fields="4"
              :autoFocus="true"
              v-on:complete="sellerEmailComplete"
              v-on:change="onChange"
            />
            <div class="pb-4">
              <b-button variant="link" @click="resendEmail(true)">
                {{$t('register.label.ResendTheCode')}}
              </b-button>
              <b-toast
                id="emailVal"
                auto-hide-delay="3000"
                title="Email Validation"
                variant="success"
              >
                {{$t('register.label.emailSent')}}
              </b-toast>
              <section v-show="errorMessage">
                <b-alert variant="danger" show>{{ errorMessage }}</b-alert>
              </section>

              <b-alert variant="success" :show="sendMessage">
                {{$t('register.label.validationSent')}}
              </b-alert>
            </div>
          </div>
        </div>
        <div slot="page3" class="text-center">
          <h2>{{$t('register.label.MobileValidation')}}</h2>

          <p>
            {{$t('register.label.validationMobileDesc',{mobile: $store.getters.user.Contact.Mobile})}}
          </p>

          <label for="">Enter Code</label>
          <CodeInput
            :key="componentKey"
            class="mx-auto"
            :loading="false"
            :fields="4"
            :autoFocus="true"
            v-on:complete="sellerMobileComplete"
            v-on:change="onChange"
          />
          <b-button variant="link" @click="resendCode(true)">
            {{$t('register.label.ResendTheCode')}}
          </b-button>
          <b-toast
            id="SMSVal"
            auto-hide-delay="3000"
            title="SMS Validation"
            variant="success"
          >
            {{$t('register.label.SMSHasSent')}}
          </b-toast>
          <section v-show="errorMessage">
            <b-alert variant="danger" show>{{ errorMessage }}</b-alert>
          </section>

          <b-alert variant="success" :show="sendMessage">
            {{$t('register.label.validationSent')}}
          </b-alert>
        </div>
      </vue-good-wizard>
    </b-card-body>
  </b-card>
</template>

<script>
import axios from "axios";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import CodeInput from "vue-verification-code-input";
export default {
  components: {
    CodeInput,
  },
  data() {
    return {
      componentKey: 0,
      errorMessage: null,
      code: null,
      sellerType: null,
      sellerEmailVerified: false,
      sendMessage: false,
      steps: [
        {
          label: "Seller Type",
          slot: "page1",
          options: {
            nextDisabled: true,
          },
        },
        {
          label: "Email Validation",
          slot: "page2",
          options: {
            nextDisabled: true,
          },
        },
        {
          label: "Mobile Validation",
          slot: "page3",
        },
      ],
    };
  },
  props: {
    accountType: {
      required: true,
      type: Number,
    },
    prevRoutePath: {
      type: String,
    },
  },
  computed: {
    sellerEmail() {
      let user = this.$store.getters.user;
      if (user) {
        return user.IsEmailVerified;
      } else return this.sellerEmailVerified;
    },
  },
  methods: {
    buyerEmailComplete(code) {
      this.loginVerify(code, true);
    },
    sellerEmailComplete(code) {
      this.loginVerify(code, false);
    },
    sellerMobileComplete(code) {
      this.loginVerify(code, true);
    },
    onChange() {
      this.errorMessage = null;
      this.sendMessage = false;
    },
    async resendEmail(isResend) {
      this.componentKey += 1;
      try {
        await axios.post("user/EmailResendCode", {
          LoginKey: this.$store.getters.tokenID,
        });
        if (isResend) {
          this.$bvToast.show("emailVal");
          this.sendMessage = true;
        }
      } catch (error) {
        this.errorMessage = error.response.data;
        console.log(error.response.data);
      }
    },
    async resendCode(isResend) {
      this.componentKey += 1;
      try {
        await axios.post("user/MobileResendCode", {
          LoginKey: this.$store.getters.tokenID,
        });
        if (isResend) {
          this.$bvToast.show("SMSVal");
          this.sendMessage = true;
        }
      } catch (error) {
        this.errorMessage = error.response.data;
        console.log(error.response.data);
      }
    },
    async loginVerify(code, finished) {
      let key = this.$store.getters.tokenID;
      try {
        const response = axios.post(
          "user/LoginVerify",
          {
            Code: code,
            LoginKey: key,
          },
          { timeout: 3000 }
        );
        if ((await response).data) {
          localStorage.setItem("token", key);
          this.overlay = false;
          await this.$store.dispatch("userFromApi", key);
          this.$root.$emit("changeLang");
          if (finished) {
            this.$router.push(this.prevRoutePath);
          } else {
            this.resendCode(false);
            this.sellerEmailVerified = true;
            this.nextButtonDisabled("page2", false);
            this.$refs["sellerRegisterWizard"].goNext(true);
          }
        } else {
          this.errorMessage = "something wrong with code, please try again";
        }
      } catch (error) {
        this.errorMessage = error.response.data;
        console.log(error);
      }
    },
    nextClicked(currentPage) {
      if (this.steps[currentPage].options.nextDisabled) return false;

      console.log("next clicked", currentPage);
      return true; //return false if you want to prevent moving to next page
    },
    backClicked(currentPage) {
      console.log("back clicked", currentPage);
      return true; //return false if you want to prevent moving to previous page
    },
    nextButtonDisabled(page, boolean) {
      this.steps.forEach((element) => {
        if (element.slot === page) {
          element.options.nextDisabled = boolean;
        }
      });
    },
    selectSellerType(id) {
      this.sellerType = id;
      this.nextButtonDisabled("page1", false);
      // this.$refs['sellerRegisterWizard'].goNext(true);
    },
  },
  mounted() {
    if (this.$store.getters.doneRegister) {
      if (!this.$store.getters.user.IsEmailVerified) {
        this.$refs["sellerRegisterWizard"].goTo(1);
      } else if (!this.$store.getters.user.IsMobileVerified) {
        this.nextButtonDisabled("page2", false);
        this.$refs["sellerRegisterWizard"].goTo(2);
      }
    }
  },
  watch: {
    sendMessage() {
      this.errorMessage = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/custom.scss";

#bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}

.wizard__body__actions {
  display: none !important;
}
.btn-tabular{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
