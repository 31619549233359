<template>
  <div class="bg-secondary">
    <b-container class="py-3">
      <div v-if="$store.getters.user">
        <TheRegisterValidation
          :accountType="$store.getters.accountType"
          :prevRoutePath="prevRoutePath"
        />
      </div>
      <b-card class="shadow mx-auto" v-else>
        <b-row class="m-0 bg-light rounded position-relative">
          <b-link class="return-button" @click="$router.back()">
            <b-icon icon="arrow-left" scale="1.5" class="mr-2" />
            {{ $t("listing.button.return") }}
          </b-link>
          <b-col class="d-none d-lg-block p-0 py-5 border-right">
            <div v-if="$store.getters.accountType === 1">
              <h2 class="text-center pb-4">
                {{ $t("register.label.buyerblurb0") }}
              </h2>
              <div class="p-4">
                <div class="row mx-5 mb-3">
                  <div class="col-1 align-self-center">
                    <b-icon
                      icon="check-circle"
                      variant="success"
                      scale="2"
                      class="my-2"
                    />
                  </div>
                  <div class="col-11 align-self-center h6">
                    {{ $t("register.label.buyerblurb1") }}
                  </div>
                </div>
                <div class="row mx-5 mb-3">
                  <div class="col-1 align-self-center">
                    <b-icon
                      icon="check-circle"
                      variant="success"
                      scale="2"
                      class="my-2"
                    />
                  </div>
                  <div class="col-11 align-self-center h6">
                    {{ $t("register.label.buyerblurb2") }}
                  </div>
                </div>
                <div class="row mx-5 mb-3">
                  <div class="col-1 align-self-center">
                    <b-icon
                      icon="check-circle"
                      variant="success"
                      scale="2"
                      class="my-2"
                    ></b-icon>
                  </div>
                  <div class="col-11 align-self-center h6">
                    {{ $t("register.label.buyerblurb3") }}
                  </div>
                </div>
                <div class="row mx-5 mb-3">
                  <div class="col-1 align-self-center">
                    <b-icon
                      icon="check-circle"
                      variant="success"
                      scale="2"
                      class="my-2"
                    ></b-icon>
                  </div>
                  <div class="col-11 align-self-center h6">
                    {{ $t("register.label.buyerblurb4") }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <h2 class="text-center pb-4">
                {{ $t("register.label.sellerblurb0") }}
              </h2>
              <div class="p-4">
                <div class="row mx-5 mb-3">
                  <div class="col-1 align-self-center">
                    <b-icon
                      icon="check-circle"
                      variant="success"
                      scale="2"
                      class="my-2"
                    ></b-icon>
                  </div>
                  <div class="col-11 align-self-center h6">
                    {{ $t("register.label.sellerblurb1") }}
                  </div>
                </div>
                <div class="row mx-5 mb-3">
                  <div class="col-1 align-self-center">
                    <b-icon
                      icon="check-circle"
                      variant="success"
                      scale="2"
                      class="my-2"
                    ></b-icon>
                  </div>
                  <div class="col-11 align-self-center h6">
                    {{ $t("register.label.sellerblurb2") }}
                  </div>
                </div>
                <div class="row mx-5 mb-3">
                  <div class="col-1 align-self-center">
                    <b-icon
                      icon="check-circle"
                      variant="success"
                      scale="2"
                      class="my-2"
                    ></b-icon>
                  </div>
                  <div class="col-11 align-self-center h6">
                    {{ $t("register.label.sellerblurb3") }}
                  </div>
                </div>
                <div class="row mx-5 mb-3">
                  <div class="col-1 align-self-center">
                    <b-icon
                      icon="check-circle"
                      variant="success"
                      scale="2"
                      class="my-2"
                    ></b-icon>
                  </div>
                  <div class="col-11 align-self-center h6">
                    {{ $t("register.label.sellerblurb4") }}
                  </div>
                </div>
              </div>
            </div>
          </b-col>

          <b-col class="m-auto px-0 py-5">
            <TheRegistration
              :accountType="$store.getters.accountType"
              :prevRoutePath="prevRoutePath"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-container>
    <!-- <router-link :to="prevRoutePath">Previous Page</router-link> -->
  </div>
</template>

<script>
import TheRegistration from "@/components/global/TheRegistration.vue";
import TheRegisterValidation from "@/components/global/TheRegisterValidation.vue";

export default {
  components: {
    TheRegistration,
    TheRegisterValidation,
  },
  data() {
    return {
      prevRoute: null,
    };
  },
  computed: {
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.path : "/";
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let verified = false;
      let user = vm.$store.getters.user;
      if (user) {
        verified = user.IsEmailVerified && user.IsMobileVerified;
      }
      if (verified) {
        vm.$router.push({ name: "Home" });
      }
      if (
        vm.$store.getters.country.Code !== "CA" &&
        vm.$store.getters.accountType === 2
      ) {
        vm.$router.back();
        vm.$bvModal.show("registerCountryNotFound");
      }
      vm.prevRoute = from;
    });
  },
  beforeMount() {
    document.getElementById("navBar").style.display = "none";
  },
  beforeDestroy() {
    this.$store.dispatch("register_emailAddress", null);
    this.$store.dispatch("doneRegister", true);
    document.getElementById("navBar").style.display = "block";
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/custom.scss";

.bg_image_seller {
  background-image: url("~@/assets/img/Register1.jpg");
  z-index: -999 !important;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.card-body {
  padding: 0;
}
.return-button {
  position: absolute;
  top: 15px;
  left: 16px;
  z-index: 2;
}
</style>
